body{
  background: #fff !important;
}
.logo{
  text-align: center;

  img{
    height: 90px;
    width: auto;
  }
}

.page-auth.page-login {
   @media (min-width: 992px) {
    display: flex;
    .unsplash-image {
      flex: 1;
      height: 100vh;
      background: midnightblue url('../img/background/Iso_Map.jpg') center center;
      background-size: cover;
    }
    .inner-page {
      min-width: 400px;
    }
    .center-block {
      width: 300px;
      margin: 0 auto;
    }
  }
}