.tuv.content-wrapper{
  padding-bottom: 100px;
}

.mb-0{
	margin-bottom: 0 !important;
}

.text-grey{
	color: #c6c6c6;
}