.text-blue{
	color: #55CAFE;
}
.text-purple{
	color: #9E9BF2;
}
.text-green{
	color: #77EFB7;
}
.brs-mini{
	border-radius: 3px;
}
.brs-1b{
	border-radius: 10px;
}

.p-1{
	padding: 10px;
}


.ml-15{
	margin-left: 15px;
}
.mr-15{
	margin-right: 15px;
}
.mx-15{
	margin-left: 15px;
	margin-right: 15px;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.bg-light2{
	background: #F9FAFC;
}


.new-card{
	background: #fff;
	border-radius: 5px;
	margin-bottom: 15px;

	.new-card-header{
		padding: 10px 15px;
		h4{
			font-size: 12px;
			margin: 0;
			padding-top: 5px;
		}
		.action{
			float: right;
		}
	}

	.new-card-body{
		position: relative;
		padding: 15px;
	}

	p.stats-title{
	margin-bottom: 0;
	margin-top: 15px;
	font-size: 10px;
	}

	h5{
	font-size: 12px;
	margin-top: 0;
	}
}

.sales-widget-list{

	ul{
		list-style: none;
		padding: 0;

		li{
			padding: 17px 10px;
			background: #eee;
			margin-bottom: 10px;

			p{
				padding: 0;
				margin: 0;
				vertical-align: center;

				span{
					float: right;
					font-size: 1.2em;
					font-weight: 500;  
				}
			}

		}
	}
}

.tuv-card2{
	position: relative;
	.title{
		.icon{
			position: absolute;
			width: 30px;
			height: 30px;
			background: #fff;
			border-radius: 50%;
			text-align: center;
			font-size: 20px;
			left: 10px;
		}
		h6{
			margin-left: 40px;
			font-size: 13px;

			span{
				display: block;
				font-size: 8px;
				padding-top: 5px;
				color: #666;
			}
		}
	}
}

.icon-bor-blue{
	border: 1px solid #55CAFE;
	color: #55CAFE;
}
.icon-bor-purple{
	border: 1px solid #A38CFB;
	color: #A38CFB;
}
.icon-bor-green{
	border: 1px solid #77EFB7;
	color: #77EFB7;
}

.Userpic{
	text-align: center;
	height: 45px;
	width: 45px;
	border-radius: 50%;
	overflow: hidden;
	img{
		height: auto;
		width: 50px;
	}
}

.avatar-table{
	text-align: center;
	width: 45px;
	span{
		display: block;
	}
}


.table-small{
	font-size: 10px;

	tbody{
		tr{
			td{
				padding: 2px !important;
				border-top: 0px !important;
			}
		}
	}
}


ul.tuv-sbaudit{
	list-style: none;
	padding: 0;
	li{
		font-size: 9px;
		margin-bottom: 5px;

		span{
			float: right;
		}
	}
}

.tuv-filter{
	font-size: 11px;
	
	.Select-control {
	    background-color: #fff;
	    border-color: #d9d9d9 #ccc #b3b3b3;
	    border-radius: 10px;
	    border: 1px solid #ccc;
	    color: #333;
	    cursor: default;
	    display: table;
	    border-spacing: 0;
	    border-collapse: separate;
	    height: 20px;
	    outline: none;
	    overflow: hidden;
	    position: relative;
	    min-width: 85px;;
	    padding: 0;

	    .Select-placeholder{
	    	line-height: 24px;
	    }

	    .Select-input {
		    height: 20px;
		    padding-left: 5px;
		    padding-right: 5px;
		    vertical-align: middle;
		}
	}
}

.left-green{
	border-left: 3px solid #77EFB7;
}
.left-purple{
	border-left: 3px solid #A38CFB;
}
.left-blue{
	border-left: 3px solid #55CAFE;
}

.rounded-1{
	border-radius: 10px!important;
}


