.table-schedule {
  .date-row {
    text-align: center;
  }

  .fixed-left {
    background: #f5f5f5;
    border-left: 1px solid #ddd;
    height: 32px;
    left: 15px;
    overflow: hidden;
    overflow-y: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;

    + td,
    + th {
      padding-left: 150px;
    }
  }

  .occupied > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  // [colspan='1'] > div {
  //   width: 25px;
  // }

  // [colspan='2'] > div {
  //   width: 50px;
  // }

  .selected {
    background: #337ab7;
    
    .reference {
      color: #ffffff;
    }
  }

  .empty-date {
    &.bg-primary {
      a {
        color: #ffffff;
      }
    }

    > div {
      overflow: visible;
      text-align: center;
    }

    .popover {
      text-align: left;
    }
  }


  .popover.new {
    color: #555555;
  }
}
